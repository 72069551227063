import React from 'react';
import './styles.scss';
import { Icon } from "@makedonski/admin-ui";
import { useState, useEffect } from 'react';
import { hideModal, showLoading, hideLoading } from "../../../actions"
import { useDispatch } from 'react-redux';
import Files from "../../../utilities/files"

export default function AddChecklistModal({ data }) {
    const dispatch = useDispatch();
    const [ file, setFile ] = useState({ name: '', url: '', inquiry: '', action: '' })

    let isDisabled = !(file?.name && file?.url && file?.inquiry && file?.action)

    useEffect(() => {
      let newFile = { ...file }
      if (data?.action) newFile.action = data?.action
      if (data?.inquiryId) newFile.inquiry = data?.inquiryId
      
      setFile(newFile)
    }, [])

  return (
    <div className='add-checklist-container'>
     <div className='modal-inner-container'>
        <div className='top-wrapper'>
            <h3>{data?.action === 'sent' ? 'Изпратен към клиент' : data?.action === 'received' ? 'Получен от клиент' : '' }</h3>
            <img className='close-btn' src={require('../../../assets/icons/closeIcon.svg')} onClick={() => dispatch(hideModal())} />
        </div>

        <div className='main-wrapper'>
            <div className='input-wrapper'>
              <div className='label'>Изпратен файл</div>

                {file?.name ?
                <div className='file-wrapper'>
                   <div className='file-name'>{file?.name || ''}</div>
                   <div className="delete-icon" onClick={() => setFile({})} />
                </div> :
                  <div className="input-file-control">
                  Добави файл
                  <Icon name="chat---add-file" size="14px" color="#7E7E7E" />
                  <input
                    type="file"
                    // ref={drawing}
                    accept="*"
                    onChange={(e) => {
                      const { name } = e.target.files[0]
                      Files.uploadFile(e.target.files[0]).then((result) => {
                        setFile({ ...file, name, url: result.location })
                      })
                    }}
                  />
                </div>}
            </div>
        </div>

        <div className='bottom-wrapper'>
            {/* <button className='primary-btn' disabled={isDisabled} onClick={() => dispatch(hideModal())}>Потвърди</button> */}
            <button
            className='primary-btn'
            disabled={isDisabled}
            onClick={() => {
              data.onSave(file)
              // dispatch(hideModal())
              }}>Потвърди</button>
        </div>
      </div>
    </div>
  )
}
