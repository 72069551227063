import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { Button, Input } from "@makedonski/admin-ui"
import { UploadFile } from "../../../components"
import Files from "../../../utilities/files"
import moment from "moment"
import {
  hideModal,
  addActions,
  searchInquiryCategories,
} from "../../../actions"
import "./styles.scss"

const AddProjectActivityModal = ({
  hide,
  add,
  inquiry,
  searchInquiryCategories,
  categories,
}) => {
  const [date, setDate] = useState(moment().toDate())
  const [text, setText] = useState("")
  const [files, setFiles] = useState([])
  const [categoryFilter, setCategoryFilter] = useState("")
  const [showCategories, setShowCategories] = useState(false)
  const [categoryID, setCategoryID] = useState(null)

  useEffect(() => {
    searchInquiryCategories({ search: "" })
  }, [searchInquiryCategories])

  return (
    <div className="add-activity-modal-container">
      <div className="add-activity-modal-top-container">
        <div />
        <div className="exit-btn" onClick={hide} />
      </div>
      <div className="project-activity-top-container">
        <h3>Добавяне на дейност</h3>
      </div>
      <span>Категория</span>
      <div className="input-wrapper">
        <Input.Text
          value={categoryFilter}
          onFocus={() => setShowCategories(true)}
          onChange={(event) => {
            setCategoryID(null)
            setCategoryFilter(event.target.value)
          }}
        />
        {showCategories && (
          <div className="category-suggestions-container">
            {categories
              .filter((cat) =>
                cat.name.toLowerCase().includes(categoryFilter.toLowerCase())
              )
              .map((el) => {
                return (
                  <p
                    onClick={() => {
                      setCategoryFilter(el.name)
                      setCategoryID(el._id)
                      setShowCategories(false)
                    }}
                  >
                    {el.name}
                  </p>
                )
              })}
          </div>
        )}
      </div>

      <span>Дата</span>
      <Input.Datepicker
        placeholder="ДД/ММ/ГГГГ"
        pickDate={date}
        onChange={(d) => setDate(d)}
      />
      <span>Файлове</span>
      <div className="flex-container">
        <UploadFile
          multiple
          onChange={(data) => {
            const nameArray = Object.keys(data.target.files).map((key) => {
              return data.target.files[key].name
            })
            Files.uploadFiles(data.target.files).then((result) => {
              console.log(result)
              let newFiles = result.map((el, index) => {
                return { name: nameArray[index], url: el.location }
              })
              newFiles = [...newFiles, ...files]
              setFiles(newFiles)
            })
          }}
        />
        {files.map((file, index) => {
          return (
            <div className="flex-container">
              <p>{file.name}</p>
              <div
                className="remove-btn"
                onClick={() => {
                  let newFiles = files.slice(0)
                  newFiles.splice(index, 1)
                  setFiles(newFiles)
                }}
              />
            </div>
          )
        })}
      </div>
      <span>Описание</span>
      <Input.Text
        value={text}
        placeholder="Описание на дейността"
        onChange={(e) => setText(e.target.value)}
      />
      <Button.Raised
        text="Добави дейност"
        onClick={() => {
          const details = {
            state: "activity",
            kind: categoryFilter,
            onDate: date,
            text,
            data: { files: files },
            category: categoryID ? categoryID : categoryFilter,
            inquiry: inquiry._id,
          }
          add(details)
        }}
      />
    </div>
  )
}

const mapStateToProps = (state) => ({
  inquiry: state.inquiries.current,
  categories: state.inquiries.categories,
})

const mapDispatchToProps = (dispatch) => ({
  hide: () => dispatch(hideModal()),
  add: (payload) => dispatch(addActions(payload)),
  searchInquiryCategories: (payload) =>
    dispatch(searchInquiryCategories(payload)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddProjectActivityModal)
