import { nanoid } from "nanoid"
import { API_URL } from "../config/settings"

const uploadFile = async (file) => {
  let filename = file.name
  filename = filename.split("%").join("").split(" ").join("")
  filename = `uploads/${nanoid()}_${filename}`
  const response = await fetch(`${API_URL}/cloudServices/s3Upload`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ objectKey: filename, mimeType: file?.type ?? "" }),
  })
  if (!response.ok) throw new Error("Грешка")
  const { presignedUrl, url } = await response.json()
  const response2 = await fetch(presignedUrl, {
    method: "PUT",
    body: file,
    headers: { "Content-Type": file?.type ?? "" },
  })
  if (!response2.ok) throw new Error("Грешка")
  return { location: url }
}

const Files = {
  async uploadFiles(files) {
    return await Promise.all([...files].map((file) => uploadFile(file)))
  },
  uploadFile,
}

export default Files
